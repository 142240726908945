import { chain, trimEnd } from 'lodash';
import { urijs } from '../helpers/urijs';
import { readableUrl } from './uri';
export var getRawOuterUrl = function (path, sectionUrl) {
    var url = new urijs(sectionUrl || '').unicode();
    if (path === '' && url.hostname() && sectionUrl.endsWith(url.hostname())) {
        return sectionUrl;
    }
    var location = new urijs(path).unicode();
    var pathname = trimEnd(readableUrl(url.pathname()), '/') + location.pathname();
    // addQuery breaks for encoded characters
    var query = chain([url.query(), location.query()])
        .compact()
        .join('&')
        .value();
    url.pathname(pathname);
    url.query(query);
    url.hash(location.hash() || url.hash());
    return url.toString();
};
export var getOuterUrl = function (path, sectionUrl) {
    return readableUrl(getRawOuterUrl(path, sectionUrl));
};
