var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5, _6, _7, _8;
import { __assign } from "tslib";
import { get } from 'lodash';
import { LAYOUT_PG_TEXT_ON_IMAGE_SMALL, LAYOUT_PG_TEXT_ON_IMAGE_MEDIUM, LAYOUT_PG_TEXT_ON_IMAGE_LARGE, LAYOUT_PG_CARD_MEDIUM, LAYOUT_PG_SIDE_BY_SIDE, LAYOUT_PG_SIDE_BY_SIDE_RIGHT, LAYOUT_PG_GRID, LAYOUT_PG_ONE_COLUMN, LAYOUT_LIST, LAYOUT_LIST_MEDIUM, LAYOUT_LIST_LARGE, LAYOUT_SLIDER, LAYOUT_PG_GRID_INTERMEDIATE, LAYOUT_PG_GRID_LARGE, } from './layout-types';
import { SECTION_HOMEPAGE, SECTION_CATEGORY, SECTION_MY_POSTS, SECTION_POST_LIST, SECTION_RELATED_POSTS, } from './sections';
export var PG_LAYOUT_IMAGE_RATIO_OPTIONS = {
    RATIO_16ON9: 1,
    RATIO_4ON3: 2,
    SQUARE: 3,
    RATIO_3ON4: 4,
    RATIO_9ON16: 5,
    RATIO_16ON7: 6, // hidden setting - used for old layouts to pro gallery migration
};
export var PG_LAYOUT_IMAGE_RATIOS_BY_ID = {
    1: 'RATIO_16ON9',
    2: 'RATIO_4ON3',
    3: 'SQUARE',
    4: 'RATIO_3ON4',
    5: 'RATIO_9ON16',
    6: 'RATIO_16ON7',
};
export var PG_LAYOUT_IMAGE_ALIGNMENT_OPTIONS = {
    RIGHT_ALIGN: 1,
    LEFT_ALIGN: 2,
};
export var PG_LAYOUT_IMAGE_CROP_TYPE_OPTIONS = {
    CROP: '0',
    MAX: '1',
};
export var PG_LAYOUT_IMAGE_RESIZING_MODE_OPTIONS = {
    PROPORTIONAL: 0,
    FIXED: 1,
};
export var PG_LAYOUT_INFO_SIZE_TYPE_OPTIONS = {
    MANUAL: 0,
    AUTO: 1,
};
export var PG_LAYOUT_CARDS_ROW_TYPE_OPTIONS = {
    FIT_TO_SCREEN: 0,
    ITEMS_PER_ROW: 1,
};
export var DEFAULT_PG_LAYOUT_IMAGE_RATIO = 'DEFAULT_PG_LAYOUT_IMAGE_RATIO';
export var DEFAULT_PG_LAYOUT_ITEM_SIZE = 'DEFAULT_PG_LAYOUT_ITEM_SIZE';
export var DEFAULT_PG_LAYOUT_CARDS_ROW_TYPE = 'DEFAULT_PG_LAYOUT_CARDS_ROW_TYPE';
export var DEFAULT_PG_LAYOUT_POSTS_PER_ROW = 'DEFAULT_PG_LAYOUT_POSTS_PER_ROW';
export var DEFAULT_PG_LAYOUT_INFO_SIZE = 'DEFAULT_PG_LAYOUT_INFO_SIZE';
export var DEFAULT_PG_LAYOUT_INFO_SIZE_TYPE = 'DEFAULT_PG_LAYOUT_INFO_SIZE_TYPE';
export var DEFAULT_PG_LAYOUT_SPACING = 'DEFAULT_PG_LAYOUT_SPACING';
export var DEFAULT_PG_LAYOUT_SIDES_PADDING = 'DEFAULT_PG_LAYOUT_SIDES_PADDING';
export var DEFAULT_PG_LAYOUT_MARGINS = 'DEFAULT_PG_LAYOUT_MARGINS';
export var DEFAULT_PG_IMAGE_ALIGNMENT = 'DEFAULT_PG_IMAGE_ALIGNMENT';
export var DEFAULT_PG_IMAGE_PROPORTIONS = 'DEFAULT_PG_IMAGE_PROPORTIONS';
export var DEFAULT_PG_IMAGE_WIDTH = 'DEFAULT_PG_IMAGE_WIDTH';
export var DEFAULT_PG_IMAGE_RESIZING_MODE = 'DEFAULT_PG_IMAGE_RESIZING_MODE';
export var DEFAULT_PG_LAYOUT_IMAGE_CROP_TYPE = 'DEFAULT_PG_LAYOUT_IMAGE_CROP_TYPE';
export var DEFAULT_PG_LAYOUT_TITLE_LINE_COUNT = 'DEFAULT_PG_LAYOUT_TITLE_LINE_COUNT';
export var DEFAULT_PG_LAYOUT_DESCRIPTION_LINE_COUNT = 'DEFAULT_PG_LAYOUT_DESCRIPTION_LINE_COUNT';
export var MIN_PG_LAYOUT_SPACNG = 'MIN_PG_LAYOUT_SPACNG';
export var MIN_PG_LAYOUT_ITEM_SIZE = 'MIN_PG_LAYOUT_ITEM_SIZE';
export var MIN_PG_LAYOUT_POSTS_PER_ROW = 'MIN_PG_LAYOUT_POSTS_PER_ROW';
export var MIN_PG_LAYOUT_INFO_SIZE = 'MIN_PG_LAYOUT_INFO_SIZE';
export var MIN_PG_LAYOUT_SIDES_PADDING = 'MIN_PG_LAYOUT_SIDES_PADDING';
export var MIN_PG_LAYOUT_MARGINS = 'MIN_PG_LAYOUT_MARGINS';
export var MIN_PG_LAYOUT_TITLE_LINE_COUNT = 'MIN_PG_LAYOUT_TITLE_LINE_COUNT';
export var MIN_PG_LAYOUT_DESCRIPTION_LINE_COUNT = 'MIN_PG_LAYOUT_DESCRIPTION_LINE_COUNT';
export var MIN_PG_IMAGE_ALIGNMENT = 'MIN_PG_IMAGE_ALIGNMENT';
export var MIN_PG_IMAGE_PROPORTIONS = 'MIN_PG_IMAGE_PROPORTIONS';
export var MAX_PG_LAYOUT_ITEM_SIZE = 'MAX_PG_LAYOUT_ITEM_SIZE';
export var MAX_PG_LAYOUT_POSTS_PER_ROW = 'MAX_PG_LAYOUT_POSTS_PER_ROW';
export var MAX_PG_LAYOUT_INFO_SIZE = 'MAX_PG_LAYOUT_INFO_SIZE';
export var MAX_PG_LAYOUT_SPACNG = 'MAX_PG_LAYOUT_SPACNG';
export var MAX_PG_LAYOUT_SIDES_PADDING = 'MAX_PG_LAYOUT_SIDES_PADDING';
export var MAX_PG_LAYOUT_MARGINS = 'MAX_PG_LAYOUT_MARGINS';
export var MAX_PG_LAYOUT_TITLE_LINE_COUNT = 'MAX_PG_LAYOUT_TITLE_LINE_COUNT';
export var MAX_PG_LAYOUT_DESCRIPTION_LINE_COUNT = 'MAX_PG_LAYOUT_DESCRIPTION_LINE_COUNT';
export var MAX_PG_IMAGE_ALIGNMENT = 'MAX_PG_IMAGE_ALIGNMENT';
export var MAX_PG_IMAGE_PROPORTIONS = 'MAX_PG_IMAGE_PROPORTIONS';
export var DEFAULT_PG_LAYOUT_SPACING_MOBILE = 'DEFAULT_PG_LAYOUT_SPACING_MOBILE';
export var DEFAULT_PG_LAYOUT_MARGINS_MOBILE = 'DEFAULT_PG_LAYOUT_MARGINS_MOBILE';
export var DEFAULT_PG_LAYOUT_IMAGE_RATIO_MOBILE = 'DEFAULT_PG_LAYOUT_IMAGE_RATIO_MOBILE';
var PG_TEXT_ON_IMAGE_MEDIUM_SETTINGS = (_a = {},
    _a[MIN_PG_LAYOUT_SPACNG] = 0,
    _a[MIN_PG_LAYOUT_ITEM_SIZE] = 150,
    _a[MIN_PG_LAYOUT_POSTS_PER_ROW] = 1,
    _a[MIN_PG_LAYOUT_SIDES_PADDING] = 0,
    _a[MIN_PG_LAYOUT_MARGINS] = 0,
    _a[MIN_PG_LAYOUT_TITLE_LINE_COUNT] = 1,
    _a[MAX_PG_LAYOUT_SPACNG] = 100,
    _a[MAX_PG_LAYOUT_ITEM_SIZE] = 1000,
    _a[MAX_PG_LAYOUT_POSTS_PER_ROW] = 5,
    _a[MAX_PG_LAYOUT_SIDES_PADDING] = 100,
    _a[MAX_PG_LAYOUT_MARGINS] = 100,
    _a[MAX_PG_LAYOUT_TITLE_LINE_COUNT] = 6,
    _a[DEFAULT_PG_LAYOUT_IMAGE_RATIO] = 3,
    _a[DEFAULT_PG_LAYOUT_ITEM_SIZE] = 454,
    _a[DEFAULT_PG_LAYOUT_CARDS_ROW_TYPE] = PG_LAYOUT_CARDS_ROW_TYPE_OPTIONS.FIT_TO_SCREEN,
    _a[DEFAULT_PG_LAYOUT_POSTS_PER_ROW] = 3,
    _a[DEFAULT_PG_LAYOUT_SPACING] = 32,
    _a[DEFAULT_PG_LAYOUT_SIDES_PADDING] = 30,
    _a[DEFAULT_PG_LAYOUT_MARGINS] = 20,
    _a[DEFAULT_PG_LAYOUT_IMAGE_CROP_TYPE] = 0,
    _a[DEFAULT_PG_LAYOUT_TITLE_LINE_COUNT] = 3,
    _a[DEFAULT_PG_LAYOUT_SPACING_MOBILE] = 20,
    _a[DEFAULT_PG_LAYOUT_MARGINS_MOBILE] = 0,
    _a[DEFAULT_PG_LAYOUT_IMAGE_RATIO_MOBILE] = 3,
    _a);
var PG_TEXT_ON_IMAGE_SMALL_SETTINGS = __assign(__assign({}, PG_TEXT_ON_IMAGE_MEDIUM_SETTINGS), (_b = {}, _b[DEFAULT_PG_LAYOUT_ITEM_SIZE] = 292, _b[DEFAULT_PG_LAYOUT_SIDES_PADDING] = 24, _b));
var PG_TEXT_ON_IMAGE_LARGE_SETTINGS = __assign(__assign({}, PG_TEXT_ON_IMAGE_MEDIUM_SETTINGS), (_c = {}, _c[DEFAULT_PG_LAYOUT_IMAGE_RATIO] = 1, _c[DEFAULT_PG_LAYOUT_ITEM_SIZE] = 1000, _c));
var PG_CARD_MEDIUM_SETTINGS = (_d = {},
    _d[MIN_PG_LAYOUT_SPACNG] = 0,
    _d[MIN_PG_LAYOUT_ITEM_SIZE] = 150,
    _d[MIN_PG_LAYOUT_POSTS_PER_ROW] = 1,
    _d[MIN_PG_LAYOUT_INFO_SIZE] = 0,
    _d[MIN_PG_LAYOUT_SIDES_PADDING] = 0,
    _d[MIN_PG_LAYOUT_MARGINS] = 0,
    _d[MIN_PG_LAYOUT_TITLE_LINE_COUNT] = 1,
    _d[MIN_PG_LAYOUT_DESCRIPTION_LINE_COUNT] = 1,
    _d[MAX_PG_LAYOUT_SPACNG] = 100,
    _d[MAX_PG_LAYOUT_ITEM_SIZE] = 1000,
    _d[MAX_PG_LAYOUT_POSTS_PER_ROW] = 5,
    _d[MAX_PG_LAYOUT_INFO_SIZE] = 700,
    _d[MAX_PG_LAYOUT_SIDES_PADDING] = 100,
    _d[MAX_PG_LAYOUT_MARGINS] = 100,
    _d[MAX_PG_LAYOUT_TITLE_LINE_COUNT] = 6,
    _d[MAX_PG_LAYOUT_DESCRIPTION_LINE_COUNT] = 8,
    _d[DEFAULT_PG_LAYOUT_ITEM_SIZE] = 454,
    _d[DEFAULT_PG_LAYOUT_CARDS_ROW_TYPE] = PG_LAYOUT_CARDS_ROW_TYPE_OPTIONS.FIT_TO_SCREEN,
    _d[DEFAULT_PG_LAYOUT_POSTS_PER_ROW] = 3,
    _d[DEFAULT_PG_LAYOUT_INFO_SIZE] = 350,
    _d[DEFAULT_PG_LAYOUT_INFO_SIZE_TYPE] = PG_LAYOUT_INFO_SIZE_TYPE_OPTIONS.MANUAL,
    _d[DEFAULT_PG_LAYOUT_SPACING] = 32,
    _d[DEFAULT_PG_LAYOUT_SIDES_PADDING] = 30,
    _d[DEFAULT_PG_LAYOUT_MARGINS] = 20,
    _d[DEFAULT_PG_LAYOUT_TITLE_LINE_COUNT] = 3,
    _d[DEFAULT_PG_LAYOUT_DESCRIPTION_LINE_COUNT] = 3,
    _d);
var PG_SIDE_BY_SIDE_SETTINGS = (_e = {},
    _e[MIN_PG_LAYOUT_ITEM_SIZE] = 450,
    _e[MIN_PG_LAYOUT_SPACNG] = 0,
    _e[MIN_PG_LAYOUT_SIDES_PADDING] = 0,
    _e[MIN_PG_LAYOUT_MARGINS] = 0,
    _e[MIN_PG_IMAGE_PROPORTIONS] = 20,
    _e[MIN_PG_LAYOUT_TITLE_LINE_COUNT] = 1,
    _e[MIN_PG_LAYOUT_DESCRIPTION_LINE_COUNT] = 1,
    _e[MAX_PG_LAYOUT_ITEM_SIZE] = 1200,
    _e[MAX_PG_LAYOUT_SPACNG] = 100,
    _e[MAX_PG_LAYOUT_SIDES_PADDING] = 100,
    _e[MAX_PG_LAYOUT_MARGINS] = 100,
    _e[MAX_PG_IMAGE_PROPORTIONS] = 60,
    _e[MAX_PG_LAYOUT_TITLE_LINE_COUNT] = 6,
    _e[MAX_PG_LAYOUT_DESCRIPTION_LINE_COUNT] = 8,
    _e[DEFAULT_PG_LAYOUT_ITEM_SIZE] = 1100,
    _e[DEFAULT_PG_LAYOUT_SPACING] = 32,
    _e[DEFAULT_PG_LAYOUT_SIDES_PADDING] = 36,
    _e[DEFAULT_PG_LAYOUT_MARGINS] = 20,
    _e[DEFAULT_PG_IMAGE_PROPORTIONS] = 50,
    _e[DEFAULT_PG_IMAGE_RESIZING_MODE] = PG_LAYOUT_IMAGE_RESIZING_MODE_OPTIONS.PROPORTIONAL,
    _e[DEFAULT_PG_IMAGE_ALIGNMENT] = 2,
    _e[DEFAULT_PG_LAYOUT_IMAGE_RATIO] = 2,
    _e[DEFAULT_PG_LAYOUT_IMAGE_CROP_TYPE] = 0,
    _e[DEFAULT_PG_LAYOUT_TITLE_LINE_COUNT] = 3,
    _e[DEFAULT_PG_LAYOUT_DESCRIPTION_LINE_COUNT] = 3,
    _e);
var PG_SIDE_BY_SIDE_RIGHT_SETTINGS = __assign(__assign({}, PG_SIDE_BY_SIDE_SETTINGS), (_f = {}, _f[DEFAULT_PG_IMAGE_ALIGNMENT] = 1, _f));
var PG_SIDE_BY_SIDE_MY_POSTS_SETTINGS = (_g = {},
    _g[DEFAULT_PG_LAYOUT_ITEM_SIZE] = 980,
    _g[DEFAULT_PG_LAYOUT_SPACING] = 24,
    _g[DEFAULT_PG_LAYOUT_SIDES_PADDING] = 30,
    _g[DEFAULT_PG_LAYOUT_MARGINS] = 30,
    _g[DEFAULT_PG_IMAGE_WIDTH] = 177,
    _g[DEFAULT_PG_IMAGE_RESIZING_MODE] = PG_LAYOUT_IMAGE_RESIZING_MODE_OPTIONS.FIXED,
    _g[DEFAULT_PG_IMAGE_ALIGNMENT] = 2,
    _g[DEFAULT_PG_LAYOUT_IMAGE_RATIO] = 3,
    _g[DEFAULT_PG_LAYOUT_IMAGE_CROP_TYPE] = 0,
    _g[DEFAULT_PG_LAYOUT_TITLE_LINE_COUNT] = 2,
    _g[DEFAULT_PG_LAYOUT_DESCRIPTION_LINE_COUNT] = 0,
    _g);
var PG_SIDE_BY_SIDE_RELATED_POSTS_SETTINGS = (_h = {},
    _h[MIN_PG_LAYOUT_ITEM_SIZE] = 450,
    _h[MIN_PG_LAYOUT_SPACNG] = 0,
    _h[MIN_PG_LAYOUT_SIDES_PADDING] = 0,
    _h[MIN_PG_LAYOUT_MARGINS] = 0,
    _h[MIN_PG_IMAGE_PROPORTIONS] = 20,
    _h[MIN_PG_LAYOUT_TITLE_LINE_COUNT] = 1,
    _h[MIN_PG_LAYOUT_DESCRIPTION_LINE_COUNT] = 1,
    _h[MAX_PG_LAYOUT_ITEM_SIZE] = 1200,
    _h[MAX_PG_LAYOUT_SPACNG] = 100,
    _h[MAX_PG_LAYOUT_SIDES_PADDING] = 100,
    _h[MAX_PG_LAYOUT_MARGINS] = 100,
    _h[MAX_PG_IMAGE_PROPORTIONS] = 60,
    _h[MAX_PG_LAYOUT_TITLE_LINE_COUNT] = 6,
    _h[MAX_PG_LAYOUT_DESCRIPTION_LINE_COUNT] = 8,
    _h[DEFAULT_PG_LAYOUT_ITEM_SIZE] = 1100,
    _h[DEFAULT_PG_LAYOUT_SPACING] = 24,
    _h[DEFAULT_PG_LAYOUT_SIDES_PADDING] = 20,
    _h[DEFAULT_PG_LAYOUT_MARGINS] = 20,
    _h[DEFAULT_PG_IMAGE_PROPORTIONS] = 50,
    _h[DEFAULT_PG_IMAGE_RESIZING_MODE] = PG_LAYOUT_IMAGE_RESIZING_MODE_OPTIONS.PROPORTIONAL,
    _h[DEFAULT_PG_IMAGE_ALIGNMENT] = PG_LAYOUT_IMAGE_ALIGNMENT_OPTIONS.RIGHT_ALIGN,
    _h[DEFAULT_PG_LAYOUT_IMAGE_RATIO] = PG_LAYOUT_IMAGE_RATIO_OPTIONS.SQUARE,
    _h[DEFAULT_PG_LAYOUT_IMAGE_CROP_TYPE] = PG_LAYOUT_IMAGE_CROP_TYPE_OPTIONS.CROP,
    _h[DEFAULT_PG_LAYOUT_TITLE_LINE_COUNT] = 3,
    _h[DEFAULT_PG_LAYOUT_DESCRIPTION_LINE_COUNT] = 3,
    _h);
var PG_GRID_SETTINGS = (_j = {},
    _j[MIN_PG_LAYOUT_SPACNG] = 0,
    _j[MIN_PG_LAYOUT_ITEM_SIZE] = 150,
    _j[MIN_PG_LAYOUT_POSTS_PER_ROW] = 1,
    _j[MIN_PG_LAYOUT_INFO_SIZE] = 0,
    _j[MIN_PG_LAYOUT_SIDES_PADDING] = 0,
    _j[MIN_PG_LAYOUT_MARGINS] = 0,
    _j[MIN_PG_LAYOUT_TITLE_LINE_COUNT] = 1,
    _j[MIN_PG_LAYOUT_DESCRIPTION_LINE_COUNT] = 1,
    _j[MAX_PG_LAYOUT_SPACNG] = 100,
    _j[MAX_PG_LAYOUT_ITEM_SIZE] = 1000,
    _j[MAX_PG_LAYOUT_POSTS_PER_ROW] = 5,
    _j[MAX_PG_LAYOUT_INFO_SIZE] = 700,
    _j[MAX_PG_LAYOUT_SIDES_PADDING] = 100,
    _j[MAX_PG_LAYOUT_MARGINS] = 100,
    _j[MAX_PG_LAYOUT_TITLE_LINE_COUNT] = 6,
    _j[MAX_PG_LAYOUT_DESCRIPTION_LINE_COUNT] = 8,
    _j[DEFAULT_PG_LAYOUT_IMAGE_RATIO] = 2,
    _j[DEFAULT_PG_LAYOUT_ITEM_SIZE] = 292,
    _j[DEFAULT_PG_LAYOUT_CARDS_ROW_TYPE] = PG_LAYOUT_CARDS_ROW_TYPE_OPTIONS.FIT_TO_SCREEN,
    _j[DEFAULT_PG_LAYOUT_POSTS_PER_ROW] = 3,
    _j[DEFAULT_PG_LAYOUT_INFO_SIZE] = 350,
    _j[DEFAULT_PG_LAYOUT_INFO_SIZE_TYPE] = PG_LAYOUT_INFO_SIZE_TYPE_OPTIONS.MANUAL,
    _j[DEFAULT_PG_LAYOUT_SPACING] = 32,
    _j[DEFAULT_PG_LAYOUT_SIDES_PADDING] = 24,
    _j[DEFAULT_PG_LAYOUT_MARGINS] = 20,
    _j[DEFAULT_PG_LAYOUT_IMAGE_CROP_TYPE] = 0,
    _j[DEFAULT_PG_LAYOUT_TITLE_LINE_COUNT] = 3,
    _j[DEFAULT_PG_LAYOUT_DESCRIPTION_LINE_COUNT] = 3,
    _j[DEFAULT_PG_LAYOUT_SPACING_MOBILE] = 20,
    _j[DEFAULT_PG_LAYOUT_MARGINS_MOBILE] = 0,
    _j[DEFAULT_PG_LAYOUT_IMAGE_RATIO_MOBILE] = 2,
    _j);
var PG_GRID_RELATED_POSTS_SETTINGS = (_k = {},
    _k[MIN_PG_LAYOUT_SPACNG] = 0,
    _k[MIN_PG_LAYOUT_ITEM_SIZE] = 150,
    _k[MIN_PG_LAYOUT_POSTS_PER_ROW] = 1,
    _k[MIN_PG_LAYOUT_INFO_SIZE] = 0,
    _k[MIN_PG_LAYOUT_SIDES_PADDING] = 0,
    _k[MIN_PG_LAYOUT_MARGINS] = 0,
    _k[MIN_PG_LAYOUT_TITLE_LINE_COUNT] = 1,
    _k[MIN_PG_LAYOUT_DESCRIPTION_LINE_COUNT] = 1,
    _k[MAX_PG_LAYOUT_SPACNG] = 100,
    _k[MAX_PG_LAYOUT_ITEM_SIZE] = 1000,
    _k[MAX_PG_LAYOUT_POSTS_PER_ROW] = 5,
    _k[MAX_PG_LAYOUT_INFO_SIZE] = 700,
    _k[MAX_PG_LAYOUT_SIDES_PADDING] = 100,
    _k[MAX_PG_LAYOUT_MARGINS] = 100,
    _k[MAX_PG_LAYOUT_TITLE_LINE_COUNT] = 6,
    _k[MAX_PG_LAYOUT_DESCRIPTION_LINE_COUNT] = 8,
    _k[DEFAULT_PG_LAYOUT_IMAGE_RATIO] = PG_LAYOUT_IMAGE_RATIO_OPTIONS.RATIO_4ON3,
    _k[DEFAULT_PG_LAYOUT_ITEM_SIZE] = 292,
    _k[DEFAULT_PG_LAYOUT_CARDS_ROW_TYPE] = PG_LAYOUT_CARDS_ROW_TYPE_OPTIONS.ITEMS_PER_ROW,
    _k[DEFAULT_PG_LAYOUT_POSTS_PER_ROW] = 3,
    _k[DEFAULT_PG_LAYOUT_INFO_SIZE] = 350,
    _k[DEFAULT_PG_LAYOUT_INFO_SIZE_TYPE] = PG_LAYOUT_INFO_SIZE_TYPE_OPTIONS.AUTO,
    _k[DEFAULT_PG_LAYOUT_SPACING] = 24,
    _k[DEFAULT_PG_LAYOUT_SIDES_PADDING] = 20,
    _k[DEFAULT_PG_LAYOUT_MARGINS] = 0,
    _k[DEFAULT_PG_LAYOUT_IMAGE_CROP_TYPE] = PG_LAYOUT_IMAGE_CROP_TYPE_OPTIONS.CROP,
    _k[DEFAULT_PG_LAYOUT_TITLE_LINE_COUNT] = 3,
    _k[DEFAULT_PG_LAYOUT_DESCRIPTION_LINE_COUNT] = 3,
    _k[DEFAULT_PG_LAYOUT_SPACING_MOBILE] = 20,
    _k[DEFAULT_PG_LAYOUT_MARGINS_MOBILE] = 0,
    _k[DEFAULT_PG_LAYOUT_IMAGE_RATIO_MOBILE] = 2,
    _k);
var PG_GRID_INTERMEDIATE_SETTINGS = __assign(__assign({}, PG_GRID_SETTINGS), (_l = {}, _l[DEFAULT_PG_LAYOUT_ITEM_SIZE] = 454, _l));
var PG_GRID_LARGE_SETTINGS = __assign(__assign({}, PG_GRID_SETTINGS), (_m = {}, _m[DEFAULT_PG_LAYOUT_IMAGE_RATIO] = 1, _m[DEFAULT_PG_LAYOUT_ITEM_SIZE] = 1000, _m));
var PG_ONE_COLUMN_SETTINGS = (_o = {},
    _o[MIN_PG_LAYOUT_SPACNG] = 0,
    _o[MIN_PG_LAYOUT_SIDES_PADDING] = 0,
    _o[MIN_PG_LAYOUT_MARGINS] = 0,
    _o[MIN_PG_LAYOUT_INFO_SIZE] = 0,
    _o[MIN_PG_LAYOUT_TITLE_LINE_COUNT] = 1,
    _o[MIN_PG_LAYOUT_DESCRIPTION_LINE_COUNT] = 1,
    _o[MAX_PG_LAYOUT_SPACNG] = 100,
    _o[MAX_PG_LAYOUT_SIDES_PADDING] = 100,
    _o[MAX_PG_LAYOUT_MARGINS] = 100,
    _o[MAX_PG_LAYOUT_INFO_SIZE] = 700,
    _o[MAX_PG_LAYOUT_TITLE_LINE_COUNT] = 6,
    _o[MAX_PG_LAYOUT_DESCRIPTION_LINE_COUNT] = 8,
    _o[DEFAULT_PG_LAYOUT_SPACING] = 32,
    _o[DEFAULT_PG_LAYOUT_SIDES_PADDING] = 48,
    _o[DEFAULT_PG_LAYOUT_MARGINS] = 20,
    _o[DEFAULT_PG_LAYOUT_IMAGE_RATIO] = 6,
    _o[DEFAULT_PG_LAYOUT_INFO_SIZE] = 350,
    _o[DEFAULT_PG_LAYOUT_INFO_SIZE_TYPE] = PG_LAYOUT_INFO_SIZE_TYPE_OPTIONS.MANUAL,
    _o[DEFAULT_PG_LAYOUT_TITLE_LINE_COUNT] = 3,
    _o[DEFAULT_PG_LAYOUT_DESCRIPTION_LINE_COUNT] = 3,
    _o);
var PG_LIST_SETTINGS = (_p = {},
    _p[MIN_PG_LAYOUT_SPACNG] = 0,
    _p[MIN_PG_LAYOUT_ITEM_SIZE] = 150,
    _p[MIN_PG_LAYOUT_POSTS_PER_ROW] = 1,
    _p[MIN_PG_LAYOUT_SIDES_PADDING] = 0,
    _p[MIN_PG_LAYOUT_MARGINS] = 0,
    _p[MIN_PG_LAYOUT_INFO_SIZE] = 0,
    _p[MIN_PG_IMAGE_PROPORTIONS] = 20,
    _p[MIN_PG_LAYOUT_TITLE_LINE_COUNT] = 1,
    _p[MIN_PG_LAYOUT_DESCRIPTION_LINE_COUNT] = 1,
    _p[MAX_PG_LAYOUT_SPACNG] = 100,
    _p[MAX_PG_LAYOUT_ITEM_SIZE] = 1000,
    _p[MAX_PG_LAYOUT_POSTS_PER_ROW] = 5,
    _p[MAX_PG_LAYOUT_SIDES_PADDING] = 100,
    _p[MAX_PG_LAYOUT_MARGINS] = 100,
    _p[MAX_PG_LAYOUT_INFO_SIZE] = 300,
    _p[MAX_PG_IMAGE_PROPORTIONS] = 80,
    _p[MAX_PG_LAYOUT_TITLE_LINE_COUNT] = 6,
    _p[MAX_PG_LAYOUT_DESCRIPTION_LINE_COUNT] = 8,
    _p[DEFAULT_PG_LAYOUT_SPACING] = 12,
    _p[DEFAULT_PG_LAYOUT_ITEM_SIZE] = 292,
    _p[DEFAULT_PG_LAYOUT_CARDS_ROW_TYPE] = PG_LAYOUT_CARDS_ROW_TYPE_OPTIONS.FIT_TO_SCREEN,
    _p[DEFAULT_PG_LAYOUT_POSTS_PER_ROW] = 3,
    _p[DEFAULT_PG_LAYOUT_INFO_SIZE] = 100,
    _p[DEFAULT_PG_LAYOUT_SIDES_PADDING] = 12,
    _p[DEFAULT_PG_LAYOUT_MARGINS] = 0,
    _p[DEFAULT_PG_LAYOUT_IMAGE_RATIO] = 3,
    _p[DEFAULT_PG_IMAGE_PROPORTIONS] = 25,
    _p[DEFAULT_PG_IMAGE_RESIZING_MODE] = PG_LAYOUT_IMAGE_RESIZING_MODE_OPTIONS.PROPORTIONAL,
    _p[DEFAULT_PG_IMAGE_ALIGNMENT] = 1,
    _p[DEFAULT_PG_LAYOUT_IMAGE_CROP_TYPE] = 0,
    _p[DEFAULT_PG_LAYOUT_TITLE_LINE_COUNT] = 3,
    _p[DEFAULT_PG_LAYOUT_DESCRIPTION_LINE_COUNT] = 3,
    _p[DEFAULT_PG_LAYOUT_SPACING_MOBILE] = 20,
    _p[DEFAULT_PG_LAYOUT_MARGINS_MOBILE] = 0,
    _p[DEFAULT_PG_LAYOUT_IMAGE_RATIO_MOBILE] = 3,
    _p);
var PG_LIST_MEDIUM_SETTINGS = __assign(__assign({}, PG_LIST_SETTINGS), (_q = {}, _q[DEFAULT_PG_LAYOUT_ITEM_SIZE] = 454, _q[DEFAULT_PG_LAYOUT_INFO_SIZE] = 60, _q[DEFAULT_PG_LAYOUT_MARGINS] = 20, _q[DEFAULT_PG_LAYOUT_SPACING] = 24, _q[DEFAULT_PG_IMAGE_ALIGNMENT] = 2, _q[DEFAULT_PG_LAYOUT_SIDES_PADDING] = 24, _q));
var PG_LIST_LARGE_SETTINGS = __assign(__assign({}, PG_LIST_SETTINGS), (_r = {}, _r[DEFAULT_PG_LAYOUT_ITEM_SIZE] = 1000, _r[DEFAULT_PG_LAYOUT_INFO_SIZE] = 25, _r[DEFAULT_PG_LAYOUT_MARGINS] = 20, _r[DEFAULT_PG_LAYOUT_SPACING] = 24, _r[DEFAULT_PG_IMAGE_ALIGNMENT] = 2, _r[DEFAULT_PG_LAYOUT_SIDES_PADDING] = 24, _r));
var PG_LIST_SETTINGS_MY_POSTS = (_s = {},
    _s[DEFAULT_PG_LAYOUT_SPACING] = 20,
    _s[DEFAULT_PG_LAYOUT_ITEM_SIZE] = 280,
    _s[DEFAULT_PG_LAYOUT_CARDS_ROW_TYPE] = PG_LAYOUT_CARDS_ROW_TYPE_OPTIONS.FIT_TO_SCREEN,
    _s[DEFAULT_PG_LAYOUT_POSTS_PER_ROW] = 1,
    _s[DEFAULT_PG_LAYOUT_INFO_SIZE] = 60,
    _s[DEFAULT_PG_LAYOUT_SIDES_PADDING] = 12,
    _s[DEFAULT_PG_LAYOUT_MARGINS] = 0,
    _s[DEFAULT_PG_LAYOUT_IMAGE_RATIO] = 3,
    _s[DEFAULT_PG_IMAGE_PROPORTIONS] = 32,
    _s[DEFAULT_PG_IMAGE_RESIZING_MODE] = PG_LAYOUT_IMAGE_RESIZING_MODE_OPTIONS.PROPORTIONAL,
    _s[DEFAULT_PG_IMAGE_ALIGNMENT] = 2,
    _s[DEFAULT_PG_LAYOUT_IMAGE_CROP_TYPE] = 0,
    _s[DEFAULT_PG_LAYOUT_TITLE_LINE_COUNT] = 3,
    _s);
var PG_SLIDER_SETTINGS = (_t = {},
    _t[MIN_PG_LAYOUT_INFO_SIZE] = 0,
    _t[MIN_PG_LAYOUT_SIDES_PADDING] = 0,
    _t[MIN_PG_LAYOUT_MARGINS] = 0,
    _t[MIN_PG_LAYOUT_TITLE_LINE_COUNT] = 1,
    _t[MIN_PG_LAYOUT_DESCRIPTION_LINE_COUNT] = 1,
    _t[MAX_PG_LAYOUT_INFO_SIZE] = 700,
    _t[MAX_PG_LAYOUT_SIDES_PADDING] = 48,
    _t[MAX_PG_LAYOUT_MARGINS] = 100,
    _t[MAX_PG_LAYOUT_TITLE_LINE_COUNT] = 6,
    _t[MAX_PG_LAYOUT_DESCRIPTION_LINE_COUNT] = 8,
    _t[DEFAULT_PG_LAYOUT_INFO_SIZE] = 230,
    _t[DEFAULT_PG_LAYOUT_INFO_SIZE_TYPE] = PG_LAYOUT_INFO_SIZE_TYPE_OPTIONS.MANUAL,
    _t[DEFAULT_PG_LAYOUT_SIDES_PADDING] = 24,
    _t[DEFAULT_PG_LAYOUT_MARGINS] = 0,
    _t[DEFAULT_PG_LAYOUT_IMAGE_RATIO] = 1,
    _t[DEFAULT_PG_LAYOUT_IMAGE_CROP_TYPE] = 0,
    _t[DEFAULT_PG_LAYOUT_TITLE_LINE_COUNT] = 3,
    _t[DEFAULT_PG_LAYOUT_DESCRIPTION_LINE_COUNT] = 3,
    _t[DEFAULT_PG_LAYOUT_MARGINS_MOBILE] = 0,
    _t[DEFAULT_PG_LAYOUT_IMAGE_RATIO_MOBILE] = 2,
    _t);
var LAYOUT_SETTINGS = (_u = {},
    _u[LAYOUT_PG_TEXT_ON_IMAGE_SMALL] = (_v = {},
        _v[SECTION_HOMEPAGE] = PG_TEXT_ON_IMAGE_SMALL_SETTINGS,
        _v[SECTION_CATEGORY] = PG_TEXT_ON_IMAGE_SMALL_SETTINGS,
        _v[SECTION_POST_LIST] = PG_TEXT_ON_IMAGE_SMALL_SETTINGS,
        _v[SECTION_RELATED_POSTS] = PG_TEXT_ON_IMAGE_SMALL_SETTINGS,
        _v[SECTION_MY_POSTS] = PG_TEXT_ON_IMAGE_SMALL_SETTINGS,
        _v),
    _u[LAYOUT_PG_TEXT_ON_IMAGE_MEDIUM] = (_w = {},
        _w[SECTION_HOMEPAGE] = PG_TEXT_ON_IMAGE_MEDIUM_SETTINGS,
        _w[SECTION_CATEGORY] = PG_TEXT_ON_IMAGE_MEDIUM_SETTINGS,
        _w[SECTION_POST_LIST] = PG_TEXT_ON_IMAGE_MEDIUM_SETTINGS,
        _w[SECTION_RELATED_POSTS] = PG_TEXT_ON_IMAGE_MEDIUM_SETTINGS,
        _w[SECTION_MY_POSTS] = PG_TEXT_ON_IMAGE_MEDIUM_SETTINGS,
        _w),
    _u[LAYOUT_PG_TEXT_ON_IMAGE_LARGE] = (_x = {},
        _x[SECTION_HOMEPAGE] = PG_TEXT_ON_IMAGE_LARGE_SETTINGS,
        _x[SECTION_CATEGORY] = PG_TEXT_ON_IMAGE_LARGE_SETTINGS,
        _x[SECTION_POST_LIST] = PG_TEXT_ON_IMAGE_LARGE_SETTINGS,
        _x[SECTION_RELATED_POSTS] = PG_TEXT_ON_IMAGE_LARGE_SETTINGS,
        _x[SECTION_MY_POSTS] = PG_TEXT_ON_IMAGE_LARGE_SETTINGS,
        _x),
    _u[LAYOUT_PG_CARD_MEDIUM] = (_y = {},
        _y[SECTION_HOMEPAGE] = PG_CARD_MEDIUM_SETTINGS,
        _y[SECTION_CATEGORY] = PG_CARD_MEDIUM_SETTINGS,
        _y[SECTION_POST_LIST] = PG_CARD_MEDIUM_SETTINGS,
        _y[SECTION_RELATED_POSTS] = PG_CARD_MEDIUM_SETTINGS,
        _y[SECTION_MY_POSTS] = PG_CARD_MEDIUM_SETTINGS,
        _y),
    _u[LAYOUT_PG_SIDE_BY_SIDE] = (_z = {},
        _z[SECTION_HOMEPAGE] = PG_SIDE_BY_SIDE_SETTINGS,
        _z[SECTION_CATEGORY] = PG_SIDE_BY_SIDE_SETTINGS,
        _z[SECTION_POST_LIST] = PG_SIDE_BY_SIDE_SETTINGS,
        _z[SECTION_RELATED_POSTS] = PG_SIDE_BY_SIDE_RELATED_POSTS_SETTINGS,
        _z[SECTION_MY_POSTS] = PG_SIDE_BY_SIDE_MY_POSTS_SETTINGS,
        _z),
    _u[LAYOUT_PG_SIDE_BY_SIDE_RIGHT] = (_0 = {},
        _0[SECTION_HOMEPAGE] = PG_SIDE_BY_SIDE_RIGHT_SETTINGS,
        _0[SECTION_CATEGORY] = PG_SIDE_BY_SIDE_RIGHT_SETTINGS,
        _0[SECTION_POST_LIST] = PG_SIDE_BY_SIDE_RIGHT_SETTINGS,
        _0[SECTION_RELATED_POSTS] = PG_SIDE_BY_SIDE_RIGHT_SETTINGS,
        _0[SECTION_MY_POSTS] = PG_SIDE_BY_SIDE_MY_POSTS_SETTINGS,
        _0),
    _u[LAYOUT_PG_GRID] = (_1 = {},
        _1[SECTION_HOMEPAGE] = PG_GRID_SETTINGS,
        _1[SECTION_CATEGORY] = PG_GRID_SETTINGS,
        _1[SECTION_POST_LIST] = PG_GRID_SETTINGS,
        _1[SECTION_RELATED_POSTS] = PG_GRID_RELATED_POSTS_SETTINGS,
        _1[SECTION_MY_POSTS] = PG_GRID_SETTINGS,
        _1),
    _u[LAYOUT_PG_GRID_INTERMEDIATE] = (_2 = {},
        _2[SECTION_HOMEPAGE] = PG_GRID_INTERMEDIATE_SETTINGS,
        _2[SECTION_CATEGORY] = PG_GRID_INTERMEDIATE_SETTINGS,
        _2[SECTION_POST_LIST] = PG_GRID_INTERMEDIATE_SETTINGS,
        _2[SECTION_RELATED_POSTS] = PG_GRID_INTERMEDIATE_SETTINGS,
        _2[SECTION_MY_POSTS] = PG_GRID_INTERMEDIATE_SETTINGS,
        _2),
    _u[LAYOUT_PG_GRID_LARGE] = (_3 = {},
        _3[SECTION_HOMEPAGE] = PG_GRID_LARGE_SETTINGS,
        _3[SECTION_CATEGORY] = PG_GRID_LARGE_SETTINGS,
        _3[SECTION_POST_LIST] = PG_GRID_LARGE_SETTINGS,
        _3[SECTION_RELATED_POSTS] = PG_GRID_LARGE_SETTINGS,
        _3[SECTION_MY_POSTS] = PG_GRID_LARGE_SETTINGS,
        _3),
    _u[LAYOUT_PG_ONE_COLUMN] = (_4 = {},
        _4[SECTION_HOMEPAGE] = PG_ONE_COLUMN_SETTINGS,
        _4[SECTION_CATEGORY] = PG_ONE_COLUMN_SETTINGS,
        _4[SECTION_POST_LIST] = PG_ONE_COLUMN_SETTINGS,
        _4[SECTION_RELATED_POSTS] = PG_ONE_COLUMN_SETTINGS,
        _4[SECTION_MY_POSTS] = PG_ONE_COLUMN_SETTINGS,
        _4),
    _u[LAYOUT_SLIDER] = (_5 = {},
        _5[SECTION_HOMEPAGE] = PG_SLIDER_SETTINGS,
        _5[SECTION_CATEGORY] = PG_SLIDER_SETTINGS,
        _5[SECTION_POST_LIST] = PG_SLIDER_SETTINGS,
        _5[SECTION_RELATED_POSTS] = PG_SLIDER_SETTINGS,
        _5[SECTION_MY_POSTS] = PG_SLIDER_SETTINGS,
        _5),
    _u[LAYOUT_LIST] = (_6 = {},
        _6[SECTION_HOMEPAGE] = PG_LIST_SETTINGS,
        _6[SECTION_CATEGORY] = PG_LIST_SETTINGS,
        _6[SECTION_POST_LIST] = PG_LIST_SETTINGS,
        _6[SECTION_RELATED_POSTS] = PG_LIST_SETTINGS,
        _6[SECTION_MY_POSTS] = PG_LIST_SETTINGS_MY_POSTS,
        _6),
    _u[LAYOUT_LIST_MEDIUM] = (_7 = {},
        _7[SECTION_HOMEPAGE] = PG_LIST_MEDIUM_SETTINGS,
        _7[SECTION_CATEGORY] = PG_LIST_MEDIUM_SETTINGS,
        _7[SECTION_POST_LIST] = PG_LIST_MEDIUM_SETTINGS,
        _7[SECTION_RELATED_POSTS] = PG_LIST_MEDIUM_SETTINGS,
        _7[SECTION_MY_POSTS] = PG_LIST_MEDIUM_SETTINGS,
        _7),
    _u[LAYOUT_LIST_LARGE] = (_8 = {},
        _8[SECTION_HOMEPAGE] = PG_LIST_LARGE_SETTINGS,
        _8[SECTION_CATEGORY] = PG_LIST_LARGE_SETTINGS,
        _8[SECTION_POST_LIST] = PG_LIST_LARGE_SETTINGS,
        _8[SECTION_RELATED_POSTS] = PG_LIST_LARGE_SETTINGS,
        _8[SECTION_MY_POSTS] = PG_LIST_LARGE_SETTINGS,
        _8),
    _u);
export var getPGLayoutSettingsConstant = function (layoutType, settingsKey, section) {
    if (section === void 0) { section = SECTION_HOMEPAGE; }
    return get(LAYOUT_SETTINGS, "".concat(layoutType, ".").concat(section, ".").concat(settingsKey));
};
