import { __spreadArray } from "tslib";
import { trimEnd } from 'lodash';
import { urijs } from '../helpers/urijs';
// normalizes url for unicode support in server side
export var normalizeUrl = function (url) { return new urijs(url).normalize().toString(); };
// decodes origin and pathname to readable unicode characters
// cannot decode search as it might contain encoded '#' character
export var readableUrl = function (uri) {
    var url = new urijs(uri);
    var pathname = url.pathname();
    var origin = url.origin();
    var readablePart = new urijs(origin + pathname).readable();
    if (pathname === '/' && !uri.endsWith('/')) {
        readablePart = trimEnd(readablePart, '/');
    }
    return readablePart + url.search() + url.hash();
};
export var joinUrlPaths = function (baseUrl, paths) {
    return urijs
        .joinPaths.apply(urijs, __spreadArray([urijs(baseUrl).pathname()], paths, false)).absoluteTo(baseUrl)
        .toString();
};
