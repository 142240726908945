export var SECTION_HOMEPAGE = 'homepage';
export var SECTION_CATEGORY = 'category';
export var SECTION_TAGS = 'tags';
export var SECTION_POST_LIST = 'post-list';
export var SECTION_RELATED_POSTS = 'related-posts';
export var SECTION_MY_POSTS = 'my-posts';
export var SECTIONS = [
    SECTION_HOMEPAGE,
    SECTION_CATEGORY,
    SECTION_POST_LIST,
    SECTION_RELATED_POSTS,
    SECTION_MY_POSTS,
];
export var FEED_PAGE_SECTIONS = [SECTION_HOMEPAGE, SECTION_CATEGORY];
